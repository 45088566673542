import React from 'react';
import { List, ThemeIcon, Spoiler } from '@mantine/core';
import Layout from '../../components/Layout';
import NavOne from '../../components/NavOne';
import { CircleCheck } from 'tabler-icons-react';
import PageHeader from '../../components/PageHeader';
import Footer from '../../components/Footer';
import Tabs from '../../components/Tabs';
import adeniyi from '../../assets/images/departments/pae/adeniyi.jpg';
import afolabi from '../../assets/images/departments/pae/afolabi.jpg';
import akinsete from '../../assets/images/departments/pae/akinsete.jpg';
import akintan from '../../assets/images/departments/pae/akintan.jpg';
import team1 from '../../assets/images/team-1-1.jpg';
import ekure from '../../assets/images/departments/pae/ekure.jpg';
import esezobor from '../../assets/images/departments/pae/esezobor.jpg';
import fajolu from '../../assets/images/departments/pae/fajolu.png';
import oduwole from '../../assets/images/departments/pae/oduwole.jpg';
import olubunmi from '../../assets/images/departments/pae/olubunmi.jpg';
import okoromah from '../../assets/images/departments/pae/okoromah.png';
import olumide from '../../assets/images/departments/pae/olumide.jpg';
import olusoji from '../../assets/images/departments/pae/olusoji.jpg';
import osinaike from '../../assets/images/departments/pae/osinaike.jpg';
import oyenusi from '../../assets/images/departments/pae/oyenusi.jpg';
import veronica from '../../assets/images/departments/pae/veronica.jpg';

const Paediatrics = () => {
  const specialities = [
    {
      name: 'Cardiology',
    },
    {
      name: 'Endocrinology/ Metabolic/ Adolescents Medicine',
    },
    {
      name: 'Heamatology / Oncology',
    },
    {
      name: 'Infectious Diseases',
    },

    {
      name: 'Dermatology',
    },
    {
      name: 'Emergency Paediatrics',
    },
    {
      name: 'Gastroenterology/ Nutrition',
    },
    {
      name: 'Neonatology/ Perinatology',
    },
    {
      name: 'Neurology',
    },
    {
      name: 'Nephrology',
    },
    {
      name: 'Respiratory',
    },
  ];

  const heads = [
    { name: 'Professor WRF Collis' },
    { name: 'Professor Olikoye Ransome-Kuti' },
    { name: 'Professor Iqbal Ahmed' },
    { name: 'Professor Akinboye Lesi' },
    { name: 'Professor Sam Olowe' },
    { name: 'Professor Femi Fadahunsi' },
    { name: 'Dr. (later Professor) Oluyinka Ogundipe' },
    { name: 'Dr. (later Professor) Adenike Grange' },
    { name: 'Dr. Adegboluga Adenuga' },
    { name: 'Dr. (later Professor) Matthias Egri-Okwaji' },
    { name: 'Dr. (later Professor) Edna Iroha' },
    { name: 'Dr. (later Professor) Adebola Akinsulie' },
    { name: 'Dr. (later Professor) Afolabi Lesi' },
    { name: 'Professor Edamisan Temiye' },
    { name: 'Professor Christy Okoromah' },
    { name: 'Professor Abiola Oduwole (current)' },
  ];

  const phil = [
    { name: 'Integrity and justice ' },
    { name: 'Excellence and distinction' },
    { name: 'Quality and safety ' },
    { name: 'Transparency and precision' },
    { name: 'Diversity and equity ' },
    { name: 'Creativity and innovation' },
    { name: 'Global competitiveness and visibility' },
    { name: 'Research and scholarship' },
    { name: 'Multidisciplinary and transdisciplinary approach' },
  ];
  return (
    <Layout pageTitle='Department of Paediatrics | LUTH'>
      <NavOne />
      <PageHeader title='Department of Paediatrics' />
      <section className='course-details'>
        <div className='container'>
          <div className='row'>
            <div className='col-lg-10'>
              <div className='course-details__content'>
                <div className='course-details__top'>
                  <div className='course-details__top-left'>
                    <h2 className='course-details__title'>
                      Department of Paediatrics
                    </h2>
                  </div>
                </div>
                <Tabs>
                  <div label='Overview'>
                    <Spoiler
                      maxHeight={300}
                      hideLabel='Hide'
                      showLabel='Show More'
                    >
                      <p className='course-details__tab-text'>
                        The uniqueness of our specialty-Paediatrics and the
                        wide-ranging subspecialties anchors on the diverse
                        developmental age categories of children and scope of
                        services we provide to promote health, prevent
                        diseases/injuries, and treat diseases/injuries in
                        children. Inclusive of premature and term newborns at
                        birth, infants, toddlers, young child, older child,
                        adolescents and young adults within the ages 0-18 years.
                        In school and out of school children and during their
                        transition to young adulthood (18-21years). We pride
                        ourselves on this privilege and awesome responsibility
                        of nurturing today’s children, who are tomorrows adults
                        and leaders!.
                        <br />
                        <br /> Children differ from adults anatomically,
                        physiologically, immunologically, psychologically,
                        developmentally, and metabolically. Helping children in
                        their uniqueness to attain their full biological,
                        physical, psychological, mental and social potentials
                        remains our ultimate goal. <br />
                        <br />
                        We accomplish these laudable goals by promoting health,
                        preventing disease//injury and managing any disorder
                        that may occur. This overarching goal is fundamental to
                        our core mandates of medical education, patient care,
                        research, community and child global health engagements.{' '}
                        <br />
                        <br /> We partner with individuals, families,
                        communities, primary health centres and networks,
                        schools, foster homes, medical homes, government and
                        non-governmental agencies, professional groups,
                        foundations and other stakeholders in both public and
                        private sectors to achieve our goals and objectives to
                        provide access to healthcare to children in our
                        neighbourhood communities and everywhere!.
                        <br />
                        <br />
                        We hope you will be inspired by our vision, mission,
                        values, goals and objectives to join us in this awesome
                        responsibility of caring for todays children everywhere,
                        who are tomorrows adults and leaders! <br />
                        <br />
                        <strong>Our Vision</strong> is to be competitive and
                        visible locally, nationally, regionally and globally in
                        our core mandates of medical education, patient care,
                        research, community and child global health through
                        strategic planning and partnership with the private and
                        public sectors.and advocacy
                        <br />
                        <br />
                        <strong>Our Mission</strong> is to be a reference point
                        and leader in the following:
                        <br />
                        Transforming paediatric medical education across it’s
                        continuum of undergraduate, postgraduate and continuing
                        medical education.
                        <br /> Providing quality patient care that is safe,
                        effective and evidence-based, timely, patient-centred,
                        efficient, and equitable
                        <br />
                        Conducting and disseminating cutting-edge translational
                        and life-course, multidisciplinary and
                        interdisciplinary, clinical, basic and population
                        research.
                        <h2
                          className='blog-one__title'
                          style={{ marginTop: 25 }}
                        >
                          Brief History of the Department of Paediatrics
                        </h2>
                        The Department of Paediatrics was established in 1963 to
                        provide holistic care for every child in health and
                        sickness, train medical and other healthcare
                        professionals, conduct and coordinate hospital and
                        community-based research within and without, in
                        collaboration with allied specialties and
                        subspecialties. Professor W. R. F (Bob) Collis, an
                        Irishman, was the founding Head of Department
                        (1962-1966). He along with foundation staff had been
                        recruited from the University College, Ibadan (later
                        University of Ibadan). Bob Collis later went on to
                        establish the Department of Paediatrics at the Ahmadu
                        Bello University (ABU), Zaria along with another
                        foundation staff, Dr. (later Professor) Ishaya Audu.
                        Professor Collis was succeeded by Professor Olikoye
                        Ransome-Kuti, who served as the first indigenous Head of
                        Department. Together with pioneer staff, Professor
                        Ransome-Kuti, established strong community outreach
                        programs with health centres at Gbaja and Randle and
                        developed community-based research child health and
                        paediatrics in collaboration with international
                        organizations including Rockefeller Foundation, a
                        United-States of America-based prestigious private
                        organization. It is on record that buildings at the then
                        Randle Health Centre were constructed and established in
                        1964 from grants from the Rockefeller Foundation. <br />
                        <br />
                        From a humble but auspicious beginning, our pioneer
                        faculty contributed to the development of healthcare and
                        other sectors of the economy locally, nationally,
                        regionally and internationally. The conceptualization of
                        the need for Primary healthcare was developed by
                        Professor Ransome-Kuti from his experiences of the
                        futility of seeing children die in our children’s
                        emergency room after presenting too late. The leadership
                        qualities of our staff from inception have been
                        demonstrated by the high offices held. They include:
                        Prof. Olikoye Ransome-Kuti and Prof. Adenike Grange, as
                        Honorable Ministers of Health. Professor Ishaya Audu was
                        a former Minister of External Affairs as well as being a
                        former Vice Chancellor of ABU, Zaria and Prof Fatiu
                        Akesode (former VC-LASU) among several others.
                        <h2
                          className='blog-one__title'
                          style={{ marginTop: 25 }}
                        >
                          Past Head of Department
                        </h2>
                        <List
                          spacing='xs'
                          size='sm'
                          center
                          icon={
                            <ThemeIcon color='teal' size={24} radius='xl'>
                              <CircleCheck size={16} />
                            </ThemeIcon>
                          }
                        >
                          {heads.map((data) => (
                            <List.Item key={data.name}>{data.name}</List.Item>
                          ))}
                        </List>
                        <h2
                          className='blog-one__title'
                          style={{ marginTop: 25 }}
                        >
                          Our Philosophy and Core Values
                        </h2>
                        <List
                          spacing='xs'
                          size='sm'
                          center
                          icon={
                            <ThemeIcon color='teal' size={24} radius='xl'>
                              <CircleCheck size={16} />
                            </ThemeIcon>
                          }
                        >
                          {phil.map((data) => (
                            <List.Item key={data.name}>{data.name}</List.Item>
                          ))}
                        </List>
                        <h2
                          className='blog-one__title'
                          style={{ marginTop: 25 }}
                        >
                          Specialities
                        </h2>
                        <List
                          spacing='xs'
                          size='sm'
                          center
                          icon={
                            <ThemeIcon color='teal' size={24} radius='xl'>
                              <CircleCheck size={16} />
                            </ThemeIcon>
                          }
                        >
                          {specialities.map((data) => (
                            <List.Item key={data.name}>{data.name}</List.Item>
                          ))}
                        </List>
                      </p>
                    </Spoiler>
                  </div>
                  <div label='Services'>
                    <Spoiler
                      maxHeight={300}
                      hideLabel='Hide'
                      showLabel='Show More'
                    >
                      <h2 className='blog-one__title' style={{ marginTop: 25 }}>
                        Patient Care
                      </h2>
                      <p className='course-details__tab-text'>
                        Our faculty and trainees provide patient care and child
                        health services at the Lagos University Teaching
                        Hospital (LUTH) located within the same compound as the
                        College of Medicine University of Lagos. We deliver
                        compassionate, personalized, patient-centred,
                        family-focused care to all children across their diverse
                        ages, from birth, infancy, childhood, adolescence into
                        young adulthood. Our patient care services are provided
                        through our in-born and out-born neonatal wards (D1 and
                        NNU), children’s’ wards (D2 and D3, Oncology) Olikoye
                        Ransome-Kuti Children’s Emergency Centre (ORKCHEC) and
                        the outpatient clinics comprising sub-specialty clinics,
                        disease specific sub-specialty clinics, and our proposed
                        infant-child-adolescent health supervision clinic.
                        Annually, we care for between 28,000-30,000 children and
                        their families. Our overarching goal is to provide
                        quality care that is safe, timely, effective and
                        evidence-based, timely, patient-centred, efficient and
                        equitable children and families through their lifespan
                        and in a conducive, safe and secure environment.
                        <List type='ordered'>
                          <List.Item>Out-patient Care</List.Item>
                          <List.Item>In-patient Care</List.Item>
                          <List.Item>Echocardiogram</List.Item>
                          <List.Item>ECG</List.Item>
                        </List>
                        <h2
                          className='blog-one__title'
                          style={{ marginTop: 25 }}
                        >
                          Endocrinology/Metabolic/Adolescent Clinic Unit
                        </h2>
                        <List type='ordered'>
                          <List.Item>Out-patient Care</List.Item>
                          <List.Item>In-patient Care</List.Item>
                          <List.Item>Growth Monitoring & Evaluation</List.Item>
                          <List.Item>
                            Endocrine Based replacement Supplementation Therapy
                          </List.Item>
                          <List.Item>
                            Diabetes Education and Management
                          </List.Item>
                        </List>
                        <h2
                          className='blog-one__title'
                          style={{ marginTop: 25 }}
                        >
                          Gastroenterology/Nutrition/Hepatology
                        </h2>
                        <List type='ordered'>
                          <List.Item>Out-patient Care</List.Item>
                          <List.Item>In-patient Care</List.Item>
                          <List.Item>Endoscopy</List.Item>
                          <List.Item>Nutrition Education</List.Item>
                        </List>
                        <h2
                          className='blog-one__title'
                          style={{ marginTop: 25 }}
                        >
                          Haematology/Oncology
                        </h2>
                        <List type='ordered'>
                          <List.Item>Out-patient Care</List.Item>
                          <List.Item>In-patient Care</List.Item>
                          <List.Item>Sickle Cell Disorder Management</List.Item>
                          <List.Item>HIV</List.Item>
                        </List>
                        <h2
                          className='blog-one__title'
                          style={{ marginTop: 25 }}
                        >
                          Infectious Disease
                        </h2>
                        <List type='ordered'>
                          <List.Item>Out-patient Care</List.Item>
                          <List.Item>In-patient Care</List.Item>
                          <List.Item>
                            Infectious Disease Monitoring & Evaluation
                          </List.Item>
                        </List>
                        <h2
                          className='blog-one__title'
                          style={{ marginTop: 25 }}
                        >
                          Neonatalogy/Perinatology
                        </h2>
                        <List type='ordered'>
                          <List.Item>Out-patient Care</List.Item>
                          <List.Item>In-patient Care</List.Item>
                          <List.Item>Perinatology</List.Item>
                          <List.Item>Neonatology</List.Item>
                          <List.Item>Neonatal Intensive Care</List.Item>
                          <List.Item>Jaundice Clinic</List.Item>
                          <List.Item>
                            Preterm and High Risk Infant Follow-up Clinic
                          </List.Item>
                          <List.Item>
                            Neonatal Pre-service Nursing Training
                          </List.Item>
                        </List>
                        <h2
                          className='blog-one__title'
                          style={{ marginTop: 25 }}
                        >
                          Nephrology Unit
                        </h2>
                        <List type='ordered'>
                          <List.Item>Out-patient Care</List.Item>
                          <List.Item>In-patient Care</List.Item>
                          <List.Item>Peritoneal Dialysis</List.Item>
                          <List.Item>Haemodialysis</List.Item>
                          <List.Item>Transplant – KIV</List.Item>
                        </List>
                        <h2
                          className='blog-one__title'
                          style={{ marginTop: 25 }}
                        >
                          Neurology Unit
                        </h2>
                        <List type='ordered'>
                          <List.Item>Out-patient Care</List.Item>
                          <List.Item>In-patient Care</List.Item>
                          <List.Item>Seizure Clinic</List.Item>
                          <List.Item>Cerebral Palsy</List.Item>
                          <List.Item>General Neurology</List.Item>
                          <List.Item>Developmental Assessment Clinic</List.Item>
                        </List>
                        <h2
                          className='blog-one__title'
                          style={{ marginTop: 25 }}
                        >
                          Respiratory Unit
                        </h2>
                        <List type='ordered'>
                          <List.Item>Out-patient Care</List.Item>
                          <List.Item>In-patient Care</List.Item>
                          <List.Item>Asthma Clinic</List.Item>
                          <List.Item>Research</List.Item>
                        </List>
                        <h2
                          className='blog-one__title'
                          style={{ marginTop: 25 }}
                        >
                          Children Emergency Unit
                        </h2>
                        <List type='ordered'>
                          <List.Item>All Paediatrics Emergency</List.Item>
                        </List>
                        <h2
                          className='blog-one__title'
                          style={{ marginTop: 22 }}
                        >
                          Community, Advocacy and Social Paediatrics (CASP)
                        </h2>
                        Our growing visibility in community, advocacy and social
                        paediatrics is grounded on the philosophy that most
                        child health problems are preventable and working in
                        collaboration with children and their families and
                        communities including schools and medical homes through
                        anticipatory guidance child health supervision, which
                        includes assessment of growth and development,
                        prevention of disease by immunization, prevention of
                        injury by education, screening for treatable conditions
                        and promotion of a healthy environment and a healthy
                        lifestyle; as well as through advocacy, capacity
                        development for community-based child health care
                        providers, we can support children to achieve their full
                        potentials biophysically, psychosocially and mentally,
                        and contribute to building a safe and secure society.
                        Our CASP program partners with neighbourhood primary
                        health networks and medical homes.
                      </p>
                    </Spoiler>
                  </div>
                  <div label='Staff'>
                    <React.Fragment>
                      <h2 className='blog-one__title'>Staff Details</h2>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={oduwole} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Professor Abiola O. O, ODUWOLE</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS; FWACP (Paed.), MAACE
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Paediatric Endocrinology
                            <br />
                            <span className='detail-head'>
                              Area of Research /Focus:
                            </span>{' '}
                            Endocrine , Diabetes Mellitus and metabolic
                            disorders (Diabetes, Growth and maturation, Obesity,
                            Calcium metabolism, Vitamin D, CHO metabolism
                            disorders, CAH, Inborn Error of Metabolism)
                            <br />
                            <span className='detail-head'>
                              Statement of Experience:
                            </span>{' '}
                            Teaching, researching and mentoring undergraduate
                            and postgraduate doctors and other health worker
                            since 1995. Chief
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={olumide} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Professor Adebola Olumide, AKINSULIE</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            B. Sc (Univ. of Ife) MBChB (Univ. of Ife) Facade
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Haematology and Oncology
                            <br />
                            <span className='detail-head'>
                              Area of Research /Focus:
                            </span>{' '}
                            • Anti- sickling agents in the management of Siickle
                            Cell Anaemia
                            <br /> • HIV management in Children <br /> • Bone
                            Marrow Transplant/Therapy in Sickle Cell Disease and
                            Childhood Oncology.
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={afolabi} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Professor Foluso Ebun Afolabi, LESI</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS (Lagos), MSc (London), FWACP (Paed), FRCP
                            (London) FNAMed
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Neurology/ Child Development
                            <br />
                            <span className='detail-head'>
                              Statement of Experience:
                            </span>{' '}
                            Academic leadership since 1995, teaching,
                            researching and mentoring undergraduate and
                            postgraduate doctors. Past Ag. Head of Department,
                            Paediatrics, Past Dean, Faculty of Clinical Sciences
                            and Immediate Past Provost, College of Medicine.
                            External Examiner, Paediatrics to various
                            universities (OOU, Ibadan, Benin) and the West
                            African College of Physicians as well as the Ghana
                            College of Physicians and Surgeons. International
                            Adviser to the Royal College of Physicians London.
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={olusoji} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Professor EDAMISAN Olusoji, TEMIYE</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS and FWACP (Paed.)
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Paediatric HIV, haematological disorders especially
                            sickle cell disease and paediatric malignancies
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={okoromah} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Professor Christy Amaiheaghara Nene, OKOROMAH</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, FMCPaed, FMEd & IFME, FAIMER Institute
                            (Philadelphia, USA), MHPE (UIC, Illinois, USA)
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Professor of Paediatrics & Child Health, Cardiology
                            and Medical Education/Consultant Paediatrician
                            <br />
                            <span className='detail-head'>
                              Statement of Experience:
                            </span>{' '}
                            Academic leadership: Head/Chairman of Department of
                            Paediatrics (2018-2021), Pioneer Director Medical
                            Education Development Unit (MEDU): -2016;
                            Coordinator major curriculum review and redesign
                            that birthed a branded SPICCES at the College of
                            Medicine (2008-2016); Course and clerkship
                            coordinator (2004-2011); Invited external examiner
                            to local and regional universities and to the
                            National Postgraduate Medical College of Nigeria.
                            Principal, Site-lead, Co-investigator to local,
                            regional and international funded research.
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={veronica} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Professor Chinyere Veronica, EZEAKA</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, MPH, FWACP(Paed) FRCPCH, MNIM
                            <br />
                            <span className='detail-head'>
                              Areas of Research focus:
                            </span>{' '}
                            • Neonatology/newborn survival: Prematurity/low
                            birth weight, Neonatal jaundice Newborn nutrition,
                            Perinatal asphyxia/neonatal resuscitation, Neonatal
                            sepsis, Prevention of mother to child transmission
                            of HIV (PMTCT)
                            <br /> • Simulation-based newborn care medical
                            education <br />• Global Health neonatal care
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={ekure} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Professor Ekanem Nsikak, EKURE</h3>
                          <p>
                            <span className='detail-head'>Designation:</span>{' '}
                            Consultant Cardiologist/Paediatrician
                            <br />
                            <span className='detail-head'>
                              Qualification:
                            </span>{' '}
                            M.B.B.Ch; FWACP (Paed); FNCS
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Paediatric health workforce
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={esezobor} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Associate Prof. Christopher, ESEZOBOR</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS MPH FMCPaed FWACP(Paed) IPNA Fellowship Diploma
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Nephrology
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={fajolu} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr Iretiola Bamikeolu FAJOLU</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, MPH, FMCPH, FWACP
                            <br />
                            <span className='detail-head'>Specialty:</span>{' '}
                            Perinatology/Neonatology
                            <br />
                            <span className='detail-head'>
                              Statement of Experience:
                            </span>{' '}
                            Dr Iretiola Fajolu obtained her medical degree from
                            the University of Lagos and her Postgraduate
                            Fellowship in Paediatrics from the Nigerian
                            Postgraduate College of Medicine. She has over 11
                            years’ experience as a lecturer, teaching and
                            mentoring undergraduate and postgraduate doctors.
                            She is actively involved in collaborative research
                            with both local and international colleagues
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={adeniyi} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr. Oluwafunmilayo Funke, ADENIYI</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS, FMCPaed, MWACP, MscCH, MD
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Gastroenterologist /Paediatrician
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={oyenusi} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr Elizabeth Eberechi, OYENUSI</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS (Lagos), MSc Paediatric Emergency Medicine
                            (Edinburgh), FMCPaed, MWACP, FESPE (European Society
                            for Paediatric Endocrinology)
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Paediatric Endocrinology
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={osinaike} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr Beatrice N., EZENWA</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            BSc (Ilorin), MBBS (Ilorin), MPH (Lagos), MSc
                            (Neonatal) (Southampton). FMCPaed (Nigeria).
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Neonatologist/Paediatrician
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={akinsete} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr Adeseye Michael AKINSETE</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS(BENIN) FWACP(Paed)
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Hematology/Oncology/Stem Cell Transplant
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={akintan} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr Patrician E, AKINTAN</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            Bm Bch, MPH, FMCPaed
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Infectious diseases/Paediatrician
                            <br />
                            <span className='detail-head'>
                              Statement of Experience:
                            </span>{' '}
                            Teaching, research and mentoring undergraduate and
                            postgraduate doctors. Coordinator Study 400 level
                            June 2018 to Jan 2021 Focal/contact person Medical
                            student Covid response
                          </p>
                        </div>
                      </div>
                      <div className='blog-details__author'>
                        <div className='blog-details__author-image'>
                          <img src={olubunmi} alt='profile person' />
                        </div>
                        <div className='blog-details__author-content'>
                          <h3>Dr Yeside Olubunmi, AKINBOLAGBE</h3>
                          <p>
                            <span className='detail-head'>Qualification:</span>{' '}
                            MBBS (Lagos), MSc Public Health (London), FMCPaed
                            <br />
                            <span className='detail-head'>
                              Sub Specialty:
                            </span>{' '}
                            Paediatric Respiratory Medicine/Pulmonology
                            <br />
                            <span className='detail-head'>
                              Statement of Experience:
                            </span>{' '}
                            A Paediatrician and clinical researcher passionate
                            about child health including disease prevention,
                            curative and rehabilitative care of children. Has
                            training in research methodology and epidemiology
                            from a master’s degree in Public health at the
                            London School of Hygiene and Tropical Medicine,
                            London, United Kingdom, and experience in carrying
                            out independent research with several publications
                            in peer reviewed journals.
                          </p>
                        </div>
                      </div>
                    </React.Fragment>
                  </div>
                  <div label='Contact'>
                    <React.Fragment>
                      <h2 className='blog-one__title'>Departmental Email</h2>
                      <p className='course-details__tab-text'>
                        <a href='mailto:paediatrics@luth.gov.ng'>                        
                        paediatrics@luth.gov.ng
                      </a>
                      </p>
                    </React.Fragment>
                  </div>
                </Tabs>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Layout>
  );
};

export default Paediatrics;
